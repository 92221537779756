import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "value", "placeholder"]
const _hoisted_3 = ["id", "value", "type", "placeholder"]
const _hoisted_4 = {
  key: 2,
  class: "hint"
}
const _hoisted_5 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_f_icon = _resolveComponent("f-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['form-control', { invalid: _ctx.invalid }])
  }, [
    _createElementVNode("label", { for: _ctx.id }, _toDisplayString(_ctx.label), 9, _hoisted_1),
    (_ctx.textarea)
      ? (_openBlock(), _createElementBlock("textarea", _mergeProps({
          key: 0,
          id: _ctx.id,
          value: _ctx.modelValue,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
          cols: "30",
          rows: "10",
          placeholder: _ctx.placeholder
        }, _ctx.$attrs), null, 16, _hoisted_2))
      : (_openBlock(), _createElementBlock("input", _mergeProps({
          key: 1,
          id: _ctx.id,
          value: _ctx.modelValue,
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
          type: _ctx.type,
          placeholder: _ctx.placeholder
        }, _ctx.$attrs), null, 16, _hoisted_3)),
    (_ctx.hint.length > 0)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
          _createVNode(_component_f_icon, { icon: "question-circle" }),
          _createTextVNode(_toDisplayString(_ctx.hint), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.invalid)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
          _createVNode(_component_f_icon, { icon: "info-circle" }),
          _createTextVNode(_toDisplayString(_ctx.message.$errors[0].$message), 1)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}