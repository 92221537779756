import type { State } from './state'

export default {
  languages(state: State) {
    return state.languages
  },
  towns(state: State) {
    return state.townsURL;
  },
  countries(state: State) {
    return state.countries
  },
  user(state: State) {
    return state.user
  },
  displayName(state: State) {
    return state.displayName;
  },
  marka(state: State) {
    return state.marka
  },
  avatar(state: State) {
    return state.photoURL;
  },
  hasDirection(state: State) {
    return state.hasDirection;
  }
};