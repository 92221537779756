import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.link)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: _ctx.to,
        class: _normalizeClass(_ctx.mode)
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      }, 8, ["to", "class"]))
    : (_openBlock(), _createElementBlock("button", _mergeProps({
        key: 1,
        class: _ctx.mode
      }, _ctx.$props), [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 16))
}