
import Backdrop from "./BaseBackdrop.vue";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    show: { type: Boolean, default: false },
    white: { type: Boolean, default: false }
  },
  components: { Backdrop },
});
