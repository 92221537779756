
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import BaseBackdrop from "../UI/BaseBackdrop.vue";
import TheSideNavToggle from "./TheSideNavToggle.vue";
import TheNavigation from "./TheNavigation.vue";
import TheLocaleDropdown from "../Locale/TheDropdown.vue";
import { useStore } from "vuex";
import type { User } from "@firebase/auth";

export default defineComponent({
  name: "TheHeader",
  components: {
    TheNavigation,
    TheSideNavToggle,
    TheLocaleDropdown,
    BaseBackdrop,
  },
  setup() {
    const isMenuOpen = ref(false);
    const store = useStore();
    const user = store.getters.user as User;
    const isNotAuthenticated = ref(!user);

    const mediaQuery = window.matchMedia('(min-width: 768px)')

    const toggleMenu = () => {
      if (mediaQuery.matches) return;
      isMenuOpen.value = !isMenuOpen.value;
    };

    const listener = () => {
      const header = document.querySelector("header")!;
      header.classList.toggle("sticky", window.scrollY > 0);
    };

    onMounted(() => window.addEventListener("scroll", listener));
    onUnmounted(() => window.removeEventListener("scroll", listener));

    return { isMenuOpen, toggleMenu, user, isNotAuthenticated };
  },
});
