
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    open: { type: Boolean, default: false },
    user: { type: Object, required: false },
    isAuthenticated: { type: Boolean, default: false }
  },
  emits: ['close'],
  setup() {
    const { t } = useI18n();

    return { t };
  },
});
