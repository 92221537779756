
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseBackdrop",
  emits: ["click"],
  props: {
    show: { type: Boolean, default: false },
    white: { type: Boolean, default: false },
  },
});
