import { createRouter, createWebHistory } from 'vue-router';
import type { RouteRecordRaw } from 'vue-router';
import store from '@/store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/results',
    name: 'Results',
    component: () =>
      import(/* webpackChunkName: "results" */ '../views/Results.vue')
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: () =>
      import(/* webpackChunkName: "signin" */ '../views/Driver/SignIn.vue'),
    meta: { layout: "AppEmptyLayout" }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () =>
      import(/* webpackChunkName: "profile" */ '../views/Driver/Profile.vue'),
    meta: { layout: "AppHeaderLayout" }
  },
  {
    path: '/verify',
    name: 'Verify',
    component: () =>
      import(/* webpackChunkName: "verify" */ '../views/Driver/Verify.vue'),
    meta: { layout: "AppEmptyLayout" }
  },
  {
    path: '/about',
    name: 'About',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () =>
      import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  },
  {
    path: '/drivers',
    name: 'Drivers',
    component: () =>
      import(/* webpackChunkName: "drivers" */ '../views/ForDrivers.vue'),
  },
  {
    path: '/passengers',
    name: 'Passengers',
    component: () =>
      import(/* webpackChunkName: "passengers" */ '../views/ForPassengers.vue'),
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () =>
      import(/* webpackChunkName: "faq" */ '../views/FAQ.vue'),
  },
  {
    path: "/:notFound(.*)",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/NotFound.vue"),
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
});

router.beforeEach(async (to, _, next) => {
  const user = store.getters.user;
  if (to.name === 'SignIn' && user) return next({ path: '/profile' })
  if (to.name === 'Profile' && !user) return next({ path: '/' });

  return next();
})

export default router;
