import type { State } from './state';
import type { User } from 'firebase/auth'

export default {
  setLocale(state: State, newLocale: string) {
    state.locale = newLocale;
  },
  setUser(state: State, user: User) {
    state.user = user;
  },
  setName(state: State, name: string) {
    state.displayName = name
  },
  setAvatar(state: State, url: string) {
    state.photoURL = url
  },
  setDirectionsStatus(state: State, n: boolean) {
    state.hasDirection = n;
  }
};