
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    id: { type: String, default: "" },
    label: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    type: { type: String, default: "text" },
    textarea: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
    message: { type: Object, required: false },
    modelValue: { type: String, required: true },
    hint: { type: String, default: "" }
  },
  emits: ["update:modelValue"],
});
