import { createApp } from 'vue';
import './firebase';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import { setupI18n } from './plugins/i18n';
import { FontAwesomeIcon } from './plugins/fontawesome';

import AppLayout from './layouts/AppLayoutDefault.vue';
import BaseButton from './components/UI/BaseButton.vue';
import BaseCard from './components/UI/BaseCard.vue';
import BaseInput from './components/UI/BaseInput.vue';
import BaseFileInput from './components/UI/BaseFileInput.vue';
import BaseSpinner from './components/UI/BaseSpinner.vue'
import BaseLoadingPage from './components/UI/BaseLoadingPage.vue'
import BaseModal from './components/UI/BaseModal.vue';

const app = createApp(App);
const i18n = setupI18n();

app.use(store);
app.use(router);
app.use(i18n);

app.component('f-icon', FontAwesomeIcon);
app.component('default-layout', AppLayout);
app.component('base-button', BaseButton);
app.component('base-card', BaseCard);
app.component('base-input', BaseInput);
app.component('base-file-input', BaseFileInput);
app.component('base-spinner', BaseSpinner);
app.component('base-loading-page', BaseLoadingPage);
app.component('base-modal', BaseModal);

app.mount('#app');
