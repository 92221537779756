import type { User } from 'firebase/auth'
const locale = localStorage.getItem('locale') || 'qq';

export interface State {
  townsURL: string;
  locale: string;
  languages: {short: string; long: string}[]
  countries: {id: string; name: string; code: string}[],
  user: User | null,
  [key: string]: any
}

const state: State = {
  townsURL: 'https://saiga-app-1-default-rtdb.firebaseio.com/towns.json',
  locale: locale,
  languages: [
    { short: "qq", long: "Qaraqalpaqsha" },
    { short: "qq-cyr", long: "Қарақалпақша" },
    { short: "kz", long: "Қазақша" },
    { short: "ru", long: "Русский" },
    { short: "uz", long: "O'zbekcha" }
  ],
  countries: [
    { id: "UZ", name: "Uzbekistan", code: "+998" },
    { id: "KZ", name: "Kazakhstan", code: "+7" },
    { id: "RU", name: "Russia", code: "+7" },
    { id: "TM", name: "Turkmenistan", code: "+993" },
    { id: "TJ", name: "Tajikistan", code: "+992" },
    { id: "KG", name: "Kyrgyzstan", code: "+996" },
  ],
  marka: [
    { id: "i0", name: "Chevrolet" },
    { id: "i1", name: "Daewoo" },
    { id: "i2", name: "Lada" },
    { id: "i3", name: "KIA" },
    { id: "i4", name: "Ford" },
    { id: "i5", name: "UAZ" },
    { id: "i6", name: "Volvo" },
    { id: "i7", name: "Volkswagen" },
    { id: "i8", name: "Largus" },
    { id: "w", name: "Basqa" },
  ],
  user: null,
  displayName: "",
  photoURL: "https://i.stack.imgur.com/l60Hf.png",
  hasDirection: false
};


export default state;