
import { defineComponent } from "vue";
import BaseBackdrop from './BaseBackdrop.vue';

export default defineComponent({
  components: { BaseBackdrop },
  props: {
    show: { type: Boolean, default: false },
    title: { type: String, default: "" },
  },
});
