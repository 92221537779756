
import { defineComponent } from "vue";

export default defineComponent({
  name: 'BaseButton',
  props: {
    mode: {
      type: String,
      default: "dark",
    },
    link: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      required: false,
    },
  },
});
