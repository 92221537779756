import { nextTick } from 'vue';
import { Composer, createI18n } from 'vue-i18n';

export const SUPPORT_LOCALES = ['qq', 'qq-cyr', 'ru', 'uz', 'kz'];
import qq from '@/locales/qq.json';

export function setupI18n() {
  const i18n = createI18n({
    legacy: false,
    locale: 'qq',
    fallbackLocale: 'qq',
    availableLocales: [],
    messages: { qq },
  });
  // setI18nLanguage(i18n, options.locale)
  return i18n;
}

export function setI18nLanguage(
  i18n: Composer<unknown, unknown, unknown, unknown, never, unknown>,
  locale: string
) {
  i18n.locale.value = locale;

  // axios.defaults.headers.common['Accept-Language'] = locale
  document.documentElement.lang = locale;
}

export async function loadLocaleMessages(
  i18n: Composer<unknown, unknown, unknown, unknown, never, unknown>,
  locale: string
) {
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `@/locales/${locale}.json`
  );

  i18n.setLocaleMessage(locale, messages.default);

  return nextTick();
}
