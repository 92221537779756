import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCaretDown,
  faChevronDown,
  faSearch,
  faCircleNotch,
  faArrowLeft,
  faQuestionCircle,
  faInfoCircle,
  faMapMarkerAlt,
  faEnvelope,
  faPhoneAlt,
  faUserCheck,
  faHourglassStart,
  faExclamationTriangle,
  faTimes,
  faCamera,
  faUser,
  faEdit,
  faSignOutAlt,
  faLocationArrow,
  faPlus,
  faExchangeAlt,
  faCalendarAlt,
  faTrashAlt,
  faImage,
} from '@fortawesome/free-solid-svg-icons';
import {
  faInstagram,
  faFacebook,
  faFacebookF,
  faTelegram,
  faTelegramPlane,
  faWhatsapp,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faInstagram,
  faFacebook,
  faFacebookF,
  faTelegram,
  faTelegramPlane,
  faWhatsapp,
  faYoutube
);
library.add(
  faCaretDown,
  faChevronDown,
  faSearch,
  faCircleNotch,
  faArrowLeft,
  faQuestionCircle,
  faInfoCircle,
  faMapMarkerAlt,
  faEnvelope,
  faPhoneAlt,
  faUserCheck,
  faHourglassStart,
  faExclamationTriangle,
  faTimes,
  faCamera,
  faUser,
  faEdit,
  faSignOutAlt,
  faLocationArrow,
  faPlus,
  faExchangeAlt,
  faCalendarAlt,
  faTrashAlt,
  faImage
);

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export { FontAwesomeIcon };
