
const defaultLayout = "default-layout";
import AppEmptyLayout from "./layouts/AppEmptyLayout.vue";
import AppHeaderLayout from "./layouts/AppHeaderLayout.vue";
import { computed, defineComponent, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default defineComponent({
  components: { AppEmptyLayout, AppHeaderLayout },
  setup() {
    const store = useStore();
    const route = useRoute();
    const loading = ref(true);

    const layout = computed(() => route.meta.layout || defaultLayout);

    onBeforeMount(async () => {
      await store.dispatch("tryLogin").catch((err) => console.log(err));
      loading.value = false;
    });

    return { loading, layout };
  },
});
