import { renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    (_ctx.show)
      ? (_openBlock(), _createElementBlock("div", _mergeProps({
          key: 0,
          class: ['backdrop', { white: _ctx.white }],
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
        }, _ctx.$attrs), [
          _renderSlot(_ctx.$slots, "default")
        ], 16))
      : _createCommentVNode("", true)
  ]))
}