
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import {
  loadLocaleMessages,
  setI18nLanguage,
  SUPPORT_LOCALES,
} from "@/plugins/i18n";
import TheLang from '@/components/SVG/TheLang.vue'

export default defineComponent({
  name: "LocaleDropdown",
  components: { TheLang },
  setup() {
    const i18n = useI18n();
    const { t, locale } = i18n;
    const store = useStore();

    const changeLocale = async (newLocale: string) => {
      if (!SUPPORT_LOCALES.includes(newLocale)) return;

      if (!i18n.availableLocales.includes(newLocale)) {
        await loadLocaleMessages(i18n, newLocale);
      }

      setI18nLanguage(i18n, newLocale);
    };

    const options = store.getters.languages;

    return { t, locale, options, changeLocale };
  },
});
