<template>
  <div class="spinner"></div>
</template>

<style scoped>
.spinner {
  --basic-spinner-dimensions: 125px;
  --border-width: 4px;
  --main-spinner-dimensions: calc(
    var(--basic-spinner-dimensions) - var(--border-width) * 2
  );
  --basic-dark-color: #212121;
  position: relative;
  width: var(--basic-spinner-dimensions);
  height: var(--basic-spinner-dimensions);
}
.spinner::before,
.spinner::after {
  content: "";
  display: block;
  position: absolute;
  border-width: 4px;
  border-style: solid;
  border-radius: 50%;
}
.spinner::before {
  width: var(--main-spinner-dimensions);
  height: var(--main-spinner-dimensions);
  border-color: var(--basic-dark-color);
  top: 0px;
  left: 0px;
  animation: scale-2 1s linear 0s infinite;
}
.spinner::after {
  width: var(--main-spinner-dimensions);
  height: var(--main-spinner-dimensions);
  border-color: var(--basic-dark-color);
  top: 0;
  left: 0;
  opacity: 0;
  animation: scale-2 1s linear 0.5s infinite;
}
</style>