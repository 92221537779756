
import { defineComponent, ref } from "vue";
import TheWarning from '@/components/SVG/TheWarning.vue'

export default defineComponent({
  components: { TheWarning },
  props: {
    id: { type: String, defult: "file-input" },
    label: { type: String, defult: "file-input" },
    multiple: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
  },
  emits: ["file", "files"],
  setup(_, { emit }) {
    const filename = ref("Choose file...");

    const listener = (event: Event) => {
      const input = event.target as HTMLInputElement;
      if (input.files) {
        filename.value = input.files[0].name;
        emit("files", input.files);
        emit("file", input.files[0]);
      }
    };

    return { listener, filename };
  },
});
