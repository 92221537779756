import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_spinner = _resolveComponent("base-spinner")!
  const _component_backdrop = _resolveComponent("backdrop")!

  return (_openBlock(), _createBlock(_component_backdrop, {
    class: "loader",
    show: _ctx.show,
    white: _ctx.white
  }, {
    default: _withCtx(() => [
      _createVNode(_component_base_spinner)
    ]),
    _: 1
  }, 8, ["show", "white"]))
}