import type { FirebaseApp } from 'firebase/app';
import type { Auth } from 'firebase/auth';
import type { Firestore } from 'firebase/firestore/lite'
import type { FirebaseStorage } from 'firebase/storage';
import type { Analytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyCqQQd1gv3qUoDPdWapkblqwgZpGWh8BTo",
  authDomain: "saiga-app-1.firebaseapp.com",
  projectId: "saiga-app-1",
  storageBucket: "saiga-app-1.appspot.com",
  messagingSenderId: "410676934868",
  appId: "1:410676934868:web:e4beac3cafe1a406392341",
  measurementId: "G-GZBSXR3KKQ"
};

let firebaseApp: FirebaseApp;
let db: Firestore;
let auth: Auth;
let storage: FirebaseStorage;
let analytics: Analytics;

export async function getFirebaseApp() {
  if (!firebaseApp) {
    const { initializeApp } = await import(/* webpackChunkName: "firebase" */ 'firebase/app');
    firebaseApp = initializeApp(firebaseConfig)
  }

  return firebaseApp;
}

export async function getFirestore() {
  if (!db) {
    const { getFirestore: gf } = await import(/* webpackChunkName: "firebase" */ 'firebase/firestore/lite');
    const app = await getFirebaseApp();
    db = gf(app);
  }

  return db;
}

export async function getAuth() {
  if (!auth) {
    const { getAuth: ga } = await import(/* webpackChunkName: "firebase" */ 'firebase/auth');
    const app = await getFirebaseApp();
    auth = ga(app);
  }

  return auth;
}

export async function getStorage() {
  if (!storage) {
    const { getStorage: gs } = await import(/* webpackChunkName: "firebase" */ 'firebase/storage');
    const app = await getFirebaseApp()
    storage = gs(app)
  }

  return storage;
}

export async function getAnalytics() {
  if (!analytics) {
    const { getAnalytics : ga } = await import(/* webpackChunkName: "firebase" */ 'firebase/analytics');
    const app = await getFirebaseApp()
    analytics = ga(app)
  }

  return storage;
}
