import type { ActionContext } from 'vuex'
import type { State } from './state'
import { User, signOut, updateProfile } from 'firebase/auth'
import { doc, getDoc, setDoc, deleteDoc, updateDoc, collection, query, where, getDocs } from "firebase/firestore/lite";
import { ref, StorageReference, uploadBytes, getDownloadURL } from 'firebase/storage'
import { getAuth, getStorage, getFirestore } from '@/firebase'

type Context = ActionContext<State, State>;

export default {
  setDirectionsStatus(context: Context, n: boolean): void {
    context.commit('setDirectionsStatus', n);
  },
  setLocale(context: Context, newLocale: string): void {
    localStorage.setItem('locale', newLocale);
    context.commit('setLocale', newLocale);
  },
  setUser(context: Context, user: User): void {
    context.commit('setUser', user)
  },
  async setName(context: Context, name: string) {
    const user = context.getters.user;
    await updateProfile(user, {
      displayName: name,
    });
    context.commit('setName', name);
  },
  async tryLogin(context: Context): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      getAuth().then(auth => {
        const unsubscribeFromAuth = auth.onAuthStateChanged((user) => {
          unsubscribeFromAuth();
          resolve(user);
        }, reject);
      });
    });
    return promise.then(user => {
      context.dispatch('setUser', user)
      return user;
    })
  },
  async logout() {
    const auth = await getAuth()
    return signOut(auth)
  },
  async upload(ctx: Context, payload: { file: File; avatar: boolean }) {
    const user = ctx.getters.user as User;
    const storage = await getStorage();
    let storageRef: StorageReference;
    if (payload.avatar) storageRef = ref(storage, `avatar/${user.uid}.${payload.file.name.split('.').pop()}`);
    else storageRef = ref(storage, `images/${user.uid}/${payload.file.name}`);
    
    const snapshot = await uploadBytes(storageRef, payload.file)
    return getDownloadURL(snapshot.ref);
  },
  async setPhoto(context: Context, photoURL: string) {
    const user = context.getters.user as User;
    await updateProfile(user, { photoURL });
    context.commit('setAvatar', photoURL);
  },
  async userData(context: Context) {
    const user = context.getters.user as User;
    const db = await getFirestore()

    const docRef = doc(db, "drivers", user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) return docSnap.data()
    else return false;
  },
  async direction(_: Context, path: string) {
    const db = await getFirestore()

    const docRef = doc(db, path);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) return docSnap.data()
    else return null;
  },
  async addDirection(context: Context, payload: {[key: string]: any}) {
    const user = context.getters.user as User;
    const db = await getFirestore()

    payload.phone = user.phoneNumber
    payload.fullname = user.displayName
    const docRef = doc(db, 'directions', user.uid);
    return setDoc(docRef, payload)
  },
  async removeDirection(context: Context) {
    const user = context.getters.user as User;
    const db = await getFirestore();

    const docRef = doc(db, 'directions', user.uid);
    return deleteDoc(docRef);
  },
  async updateUserDoc(context: Context, payload: {[key: string]: any}) {
    const user = context.getters.user as User;
    const db = await getFirestore()

    const docRef = doc(db, 'drivers', user.uid);
    return updateDoc(docRef, payload)
  },
  async getResults(_: Context, payload: {[key: string]: any}) {
    const db = await getFirestore()
    const direcRef = collection(db, 'directions')
    let q;
    if (!payload.from || !payload.to) {
      q = query(direcRef)
    } else {
      q = query(direcRef, where('from', '==', payload.from), where('to', '==', payload.to), where('type', '==', payload.type))
    }

    return getDocs(q)
  },
  async removePhoto(context: Context, p: { index: number; photos: string[] }) {
    const user = context.getters.user as User;
    const db = await getFirestore()

    const docRef = doc(db, 'drivers', user.uid);
    const carPhotos = p.photos.filter((_,i) => i !== p.index);
    return updateDoc(docRef, { carPhotos })
  },
  async sendMessage(_: Context, payload: {[key: string]: any}) {
    const name = payload.name || `${payload.firstname} ${payload.lastname}`
    const params: {[key: string]: any} = {
      type: payload.type,
      name,
      message: payload.message
    }
    if (payload.email) {
      params['email'] = payload.email;
    }
    const res = await fetch(`https://u4147.xvest5.ru/saiga/feedback.php?${serialize(params)}`)
    if (res.ok) {
      return 'success'
    }
    return 'failed'
  },
  async getTownName(_: Context, payload: {id: string; locale: string;} ) {
    const url = `https://saiga-app-1-default-rtdb.firebaseio.com/towns/${payload.id}/${payload.locale}.json`;
    console.log(url);
    const res = await fetch(url);
    if (res.ok) return res.json()

    throw new Error('Fetching town name failed');
  }
};

function serialize(obj: { [key: string]: any }) {
  const str = [];
  for (const p in obj)
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
}