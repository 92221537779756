
import { defineComponent } from "vue";
import ThePlayStore from "@/components/SVG/ThePlayStore.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: { ThePlayStore },
  name: "TheFooter",
  setup() {
    const { t } = useI18n();
    const year = new Date().getFullYear();

    return { t, year };
  },
});
