import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a428ed8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "nav-list__item"
}
const _hoisted_2 = { class: "nav-list__item" }
const _hoisted_3 = { class: "nav-list__item" }
const _hoisted_4 = { class: "nav-list__item" }
const _hoisted_5 = { class: "nav-list__item" }
const _hoisted_6 = { class: "nav-list__item" }
const _hoisted_7 = { class: "nav-list__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass(["nav-list", { open: _ctx.open }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
  }, [
    (_ctx.isAuthenticated && _ctx.user.displayName)
      ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
          _createVNode(_component_router_link, {
            to: "/profile",
            class: "nav-list__link"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.user.displayName), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("li", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/",
        class: "nav-list__link"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("nav.home")), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("li", _hoisted_3, [
      _createVNode(_component_router_link, {
        to: "/passengers",
        class: "nav-list__link"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("nav.passengers")), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("li", _hoisted_4, [
      _createVNode(_component_router_link, {
        to: "/drivers",
        class: "nav-list__link"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("nav.drivers")), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("li", _hoisted_5, [
      _createVNode(_component_router_link, {
        to: "/faq",
        class: "nav-list__link"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("nav.faq")), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("li", _hoisted_6, [
      _createVNode(_component_router_link, {
        to: "/about",
        class: "nav-list__link"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("nav.aboutUs")), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("li", _hoisted_7, [
      _createVNode(_component_router_link, {
        to: "/contact",
        class: "nav-list__link"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("nav.contact")), 1)
        ]),
        _: 1
      })
    ])
  ], 2))
}