import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2bb38f74"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown-container" }
const _hoisted_2 = {
  href: "#",
  "aria-haspopup": "true"
}
const _hoisted_3 = {
  class: "dropdown",
  "aria-label": "submenu"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheLang = _resolveComponent("TheLang")!
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", _hoisted_2, [
      _createVNode(_component_TheLang, { class: "lang-icon" }),
      _createTextVNode(" " + _toDisplayString(_ctx.t("nav.selectLang")) + ": ", 1)
    ]),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("li", {
          key: option.short
        }, [
          _createVNode(_component_base_button, {
            onClick: _withModifiers(($event: any) => (_ctx.changeLocale(option.short)), ["prevent"]),
            mode: "light",
            class: _normalizeClass({ selected: option.short === _ctx.locale }),
            to: "#",
            link: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(option.long), 1)
            ]),
            _: 2
          }, 1032, ["onClick", "class"])
        ]))
      }), 128))
    ])
  ]))
}