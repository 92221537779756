
import { defineComponent } from "vue";

export default defineComponent({
  name: "TheSideNavToggle",
  emits: ["toggle"],
  props: {
    active: { type: Boolean, default: false },
  },
});
